// src/pages/HomePage.tsx
import React, { useEffect, useState } from 'react';
import FoodCard from '../components/FoodCard';
import useWebSocket from 'react-use-websocket';
import { getFoods } from '../services/api';
import { v4 as uuidv4 } from 'uuid';
import '../styles.css';

const HomePage: React.FC = () => {
  const [foods, setFoods] = useState<any[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [match, setMatch] = useState<{ name: string; imageUrl: string } | null>(null);
  const [sessionId, setSessionId] = useState<string | null>(null);

  useEffect(() => {
    const fetchFoods = async () => {
      const data = await getFoods();
      setFoods(data);
    };
    fetchFoods();

    // Генерация уникального sessionId
    const id = uuidv4();
    setSessionId(id);
  }, []);

  const { sendMessage, lastMessage } = useWebSocket('wss://apieatpick.sworroo.me', {
    onOpen: () => console.log('WebSocket connected'),
    onClose: () => console.log('WebSocket disconnected'),
    onError: (error) => console.error('WebSocket error:', error),
    shouldReconnect: (closeEvent) => true, // Попытка переподключения при разрыве соединения
  });

  useEffect(() => {
    if (lastMessage !== null) {
      const data = JSON.parse(lastMessage.data);
      console.log(`Received message: ${JSON.stringify(data)}`);
      if (data.match) {
        setMatch(data.match);
      }
    }
  }, [lastMessage]);

  const onSwipe = (direction: 'left' | 'right') => {
    const food = foods[currentIndex];
    sendMessage(JSON.stringify({ sessionId, food, direction }));

    if (direction === 'right') {
      // Логика для лайка
    } else {
      // Логика для дизлайка
    }

    setCurrentIndex(currentIndex + 1);
  };

  return (
    <div className="container">
      {sessionId && (
        <div>
          <p>Поделитесь этой ссылкой с другом:</p>
          <a href={`https://eatpick.sworroo.me//session/${sessionId}`}>
            https://eatpick.sworroo.me/session/{sessionId}
          </a>
        </div>
      )}
      {match ? (
        <div className="match">
          <h2>Match found: {match.name}</h2>
          <img src={match.imageUrl} alt={match.name} />
        </div>
      ) : (
        foods[currentIndex] && (
          <FoodCard
            name={foods[currentIndex].name}
            imageUrl={foods[currentIndex].imageUrl}
            onSwipe={onSwipe}
          />
        )
      )}
    </div>
  );
};

export default HomePage;