// src/components/FoodCard.tsx
import React from 'react';
import '../styles.css';

interface FoodCardProps {
  name: string;
  imageUrl: string;
  onSwipe: (direction: 'left' | 'right') => void;
}

const FoodCard: React.FC<FoodCardProps> = ({ name, imageUrl, onSwipe }) => {
  const handleSwipe = (direction: 'left' | 'right') => {
    onSwipe(direction);
  };

  return (
    <div className="food-card">
      <img src={imageUrl} alt={name} />
      <h2>{name}</h2>
      <div>
        <button onClick={() => handleSwipe('left')}>Dislike</button>
        <button onClick={() => handleSwipe('right')}>Like</button>
      </div>
    </div>
  );
};

export default FoodCard;
